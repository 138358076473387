import {useState, useRef} from 'react'
import fb from '../../images/fb.png';
import twitter from '../../images/twitter.png';
import instagram from '../../images/instagram.png';
import Map from '../../images/map.png';
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';
const Contact = () => {
    const { t, i18n  } = useTranslation();
    const [inputValue, setInputValue] = useState({
        name: "",
        email: "",
        phone: "",
        msg: "",
    });
    const { name, email, phone, msg } = inputValue;
    const [status, setStatus] = useState("");
    const [missing, setMissing] = useState(false)
    const [captchaWord, setCaptchaWord] = useState('')
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setInputValue({
        ...inputValue,
        [name]: value,
        });
    };
    const handleCaptchaChange = (e) => {
        const value = e.target.value
        setCaptchaWord(value)
    }
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        if(!email || !name || !phone || !msg){
            setMissing(true);
        }
        else {
            setStatus("sending");
            emailjs.sendForm('service_wwo4big', 'template_kuds49v', form.current, 'e97JWS3qzdusKOx2F')
            .then((result) => {
                setStatus("sent");
                setTimeout(() => {
                    setStatus("")
                    setInputValue({
                        name: "",
                        email: "",
                        phone: "",
                        msg: ""
                    })
                    setCaptchaWord('')
                    setMissing(false);
                }, 2000);
            }, (error) => {
                console.log(error.text);
            });
        }
    };
    // const sendMessage = async (e) => {
    //     e.preventDefault();
    //     if(!email || !name || !phone || !msg){
    //        setMissing(true);
    //     }
    //     else {
    //         setStatus("sending");
    //         let details = {
    //         name: name,
    //         email: email,
    //         phone: phone,
    //         message: msg,
    //         };
    //         let response = await fetch("http://localhost:4000/contact", {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json;charset=utf-8",
    //         },
    //         body: JSON.stringify(details),
            
    //         });
    //         await response.json();
    //         setStatus("sent");
    //         setTimeout(() => {
    //             setStatus("")
    //             setInputValue({
    //                 name: "",
    //                 email: "",
    //                 phone: "",
    //                 msg: ""
    //             })
    //             setCaptchaWord('')
    //             setMissing(false);
    //         }, 8000);
    //     }
    //   };
    return ( 
        <>
        <div className="container mt-5">
            <h1 className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-black': 'cairo-black'}>{t("contact_us")}</span></h1>
            <h3 className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("contact_us_desc")}</span></h3>
            <div className="row mt-3">
                <div className="col-12 col-md-6 mt-5">
                    <form ref={form}>
                        <div className="mb-3 mt-3">
                            <label htmlFor="name" className="form-label"><span className={i18n.language === 'en' ?  'montserrat-regular': 'cairo-regular'}>{t("name")}</span>:</label>
                            <input type="text" className={i18n.language === 'en' ?  'form-control montserrat-extralight': 'form-control cairo-light'} id="name" placeholder={t("name_desc")} name="name" value={name} onChange={handleOnChange} required />
                        </div>
                        <div className="mb-3 mt-3">
                            <label htmlFor="email" className="form-label"><span className={i18n.language === 'en' ?  'montserrat-regular': 'cairo-regular'}>{t("your_email")}</span>:</label>
                            <input type="email"  className={i18n.language === 'en' ?  'form-control montserrat-extralight': 'form-control cairo-light'} id="email" placeholder={t("your_email_desc")} name="email" value={email} onChange={handleOnChange} required/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phone" className="form-label "><span className={i18n.language === 'en' ?  'montserrat-regular': 'cairo-regular'}>{t("your_phone")}</span>:</label>
                            <input type="tel" className={i18n.language === 'en' ?  'form-control montserrat-extralight': 'form-control cairo-light'} id="phone" placeholder={t("your_phone_desc")} name="phone" value={phone} onChange={handleOnChange} required/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="msg" className="form-label "><span className={i18n.language === 'en' ?  'montserrat-regular': 'cairo-regular'}>{t("your_msg")}</span>:</label>
                            <textarea type="textarea" className={i18n.language === 'en' ?  'form-control montserrat-extralight': 'form-control cairo-light'} id="msg" placeholder={t("your_msg_desc")} name="msg" value={msg} onChange={handleOnChange} required/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="captchamsg" className="form-label "><span className={i18n.language === 'en' ?  'montserrat-regular': 'cairo-regular'}>{t("captcha_msg")} <span className="text-secondary montserrat-bold">Summit Partners Contracting</span></span>:</label>
                            <input type="text" className={i18n.language === 'en' ?  'form-control montserrat-extralight': 'form-control cairo-light'} id="captchamsg" placeholder="Summit Partners Contracting" name="captchamsg" value={captchaWord} onChange={handleCaptchaChange} required/>
                        </div>
                        {captchaWord !== "Summit Partners Contracting" && <center><button  type="submit" className="btn disabled habout-btn"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("submit")}</span></button></center>}
                        {captchaWord === "Summit Partners Contracting" && <center><button onClick={sendEmail} type="submit" className="btn habout-btn"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("submit")}</span></button></center>}
                        {status === 'sending' && <div className="alert alert-warning mt-3"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("sending")}</span></div>}
                        {status === 'sent' && <div className="alert alert-success mt-3"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("sent")}</span></div>}
                        {missing && <div className="alert alert-danger mt-3"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("missing")}</span></div>}
                    </form>
                </div>
                <div className="col-12 col-md-6 bg-light mt-5">
                    <h1 className="text-info text-center mt-3"><span className={i18n.language === 'en' ?  'montserrat-regular': 'cairo-regular'}>{t("our_contact")}</span></h1>
                    <h4 className="text-secondary"><i className="ri-mail-fill text-secondary"></i> <span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("email_contact")}</span>:</h4>
                    <p className="text-info"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("email")}</span></p>
                    <h4 className="text-secondary"><i className="ri-map-pin-2-fill text-secondary"></i><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("our_location")}</span>:</h4>
                    <p className="text-info"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("location")}</span>​</p>
                    <img src={Map} alt="Summit Parnters Contracting Location - موقع شركاء القمة للمقاولات" className="img-fluid d-block mx-auto mb-3"/>
                </div>
            </div>
            <div className="row mt-5">
                <h1 className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-bolder': 'cairo-bolder'}>{t("connect_with_us")}</span></h1>
                <div className="col-12 col-md-4">
                    <img src={fb} alt="Summit Partners Contracting Facebook QR Code - فيسبوك شركاء القمة للمقاولات" className="mx-auto d-block mt-4"/>
                </div>
                <div className="col-12 col-md-4">
                    <img src={twitter} alt="Summit Partners Contracting Twitter QR Code - تويتر شركاء القمة للمقاولات" className="mx-auto d-block mt-4"/>
                </div>
                <div className="col-12 col-md-4">
                    <img src={instagram} alt="Summit Partners Contracting Instagram QR Code - انستجرام شركاء القمة للمقاولات" className="mx-auto d-block mt-4"/>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default Contact;