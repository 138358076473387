import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/WebLogo.png';
import { useTranslation } from "react-i18next";
const Header = () => {
  const { t, i18n  } = useTranslation();
  return (
    <div className="nav-app">
        <div className="container top-nav my-2 d-none d-lg-block">
            <div className="row">
                <div className="col-8">
                    <div className="ms-4">
                            < i className="ri-map-pin-2-fill px-1 text-secondary"></i> <span className="small"><span className={i18n.language === 'en' ?  'montserrat-light text-start pe-2': 'cairo-light text-end ps-2'}>{t("location")}</span></span>
                            <span className={i18n.language === 'en' ?  'montserrat-light text-start': 'd-none'}>< i className="ri-mail-fill px-1 text-secondary"></i> <span className="small pe-2">admin@summit-partners-ksa.com</span></span>
                    </div>    
                </div>
                <div className="col-4">
                    <div>
                        <a href="https://facebook.com/contractingsp" target="_blank" rel="noreferrer" className="unlink text-secondary"><i className="ri-facebook-fill px-3"></i></a>
                        <a href="https://instagram.com/contractingsp" target="_blank" rel="noreferrer" className="unlink text-secondary">< i className="ri-instagram-fill px-3"></i></a>
                        <a href="https://twitter.com/contractingsp" target="_blank" rel="noreferrer" className="unlink text-secondary">< i className="ri-twitter-fill px-3"></i></a>
                    </div>    
                </div>
            </div>
        </div>
        <nav className="navbar navbar-expand-sm bg-primary">
            {/* <div className="row bg-success"> */}
                <div className="col-12 col-md-4">
                    {i18n.language === 'en' ? <Link to="/"><img src={Logo} alt="Summit Partners Contracting Logo - شعار شركاء القمة للمقاولات" className="nav-logo d-none d-md-block ms-3"/></Link> : <Link to="/"><img src={Logo} alt="Summit Partners Contracting Logo" className="nav-logo d-none d-md-block me-3"/></Link> }
                    <Link to="/"><center><img src={Logo} alt="Summit Partners Contracting Logo - شعار شركاء القمة للمقاولات" className="nav-logo d-lg-none d-md-none"/></center></Link>
                </div>
                <div className="col-12 col-md-8 text-white text-uppercase default">
                    <h2 className='d-none d-lg-block '><span className={i18n.language === 'en' ?  'montserrat-black text-start': 'cairo-black text-end'}>{t("site_title")}</span></h2>
                    <h4 className='d-none d-lg-none d-md-block montserrat-black'><span className={i18n.language === 'en' ?  'montserrat-black text-start': 'cairo-black text-end'}>{t("site_title")}</span></h4>
                    <h5 className='d-md-none d-lg-none text-center montserrat-black'><span className={i18n.language === 'en' ?  'montserrat-black': 'cairo-black'}>{t("site_title")}</span></h5>
                </div>
            {/* </div> */}
        </nav>
    </div>
  )
}

export default Header